(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/on-scroll-end/assets/javascripts/on-scroll-end.js') >= 0) return;  svs.modules.push('/components/lb-utils/on-scroll-end/assets/javascripts/on-scroll-end.js');

'use strict';

const onScrollEnd = async function (ref, callback) {
  let idleFrames = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 6;
  const el = ref.current || ref;
  const p = {
    x: el.scrollLeft,
    y: el.scrollTop
  };
  let lastFrame = 0;
  await new Promise(resolve => {
    const tick = frame => {
      if (frame >= 500 || frame - lastFrame > idleFrames) {
        resolve();
      } else {
        if (el.scrollLeft !== p.x || el.scrollTop !== p.y) {
          lastFrame = frame;
          p.x = el.scrollLeft;
          p.y = el.scrollTop;
        }
        requestAnimationFrame(tick.bind(null, frame + 1));
      }
    };
    tick(0);
  });
  callback();
};
setGlobal('svs.components.lbUtils', {
  onScrollEnd
});

 })(window);